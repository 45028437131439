import React, { useState } from "react";
import { ResponsiveLine } from "@nivo/line";

export const LineGraphHome = (props) => {
  return (
    <div style={{ height: "300px", width: "100%" }}>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "transportation",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "count",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        colors={{ scheme: "nivo" }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

const data = [
  {
    id: "Empresa 1",
    color: "hsl(130, 70%, 50%)",
    data: [
      {
        x: "Janeiro",
        y: 206,
      },
      {
        x: "Fevereiro",
        y: 277,
      },
      {
        x: "Março",
        y: 45,
      },
      {
        x: "Abril",
        y: 227,
      },
      {
        x: "Maio",
        y: 297,
      },
      {
        x: "Junho",
        y: 298,
      },
      {
        x: "Julho",
        y: 114,
      },
      {
        x: "Agosto",
        y: 219,
      },
      {
        x: "Setembro",
        y: 219,
      },
      {
        x: "Outubro",
        y: 260,
      },
      {
        x: "Novembro",
        y: 108,
      },
      {
        x: "Dezembro",
        y: 287,
      },
    ],
  },
  {
    id: "Empresa 2",
    color: "hsl(230, 70%, 50%)",
    data: [
      {
        x: "Janeiro",
        y: 197,
      },
      {
        x: "Fevereiro",
        y: 184,
      },
      {
        x: "Março",
        y: 177,
      },
      {
        x: "Abril",
        y: 78,
      },
      {
        x: "Maio",
        y: 229,
      },
      {
        x: "Junho",
        y: 70,
      },
      {
        x: "Julho",
        y: 281,
      },
      {
        x: "Agosto",
        y: 166,
      },
      {
        x: "Setembro",
        y: 151,
      },
      {
        x: "Outubro",
        y: 255,
      },
      {
        x: "Novembro",
        y: 232,
      },
      {
        x: "Dezembro",
        y: 185,
      },
    ],
  },
  {
    id: "Business 3",
    color: "hsl(147, 70%, 50%)",
    data: [
      {
        x: "Janeiro",
        y: 256,
      },
      {
        x: "Fevereiro",
        y: 224,
      },
      {
        x: "Março",
        y: 36,
      },
      {
        x: "Abril",
        y: 204,
      },
      {
        x: "Maio",
        y: 93,
      },
      {
        x: "Junho",
        y: 229,
      },
      {
        x: "Julho",
        y: 11,
      },
      {
        x: "Agosto",
        y: 90,
      },
      {
        x: "Setembro",
        y: 2,
      },
      {
        x: "Outubro",
        y: 79,
      },
      {
        x: "Novembro",
        y: 183,
      },
      {
        x: "Dezembro",
        y: 85,
      },
    ],
  },
];
