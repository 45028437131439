import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { tokenValidation, dateValidation } from "@services/index.js";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import axios, { isCancel, AxiosError } from "axios";
import Loader from "@components/Loader";
import { LineGraphHome } from "@components/index.js";

import SimpleImageSlider from "react-simple-image-slider";

const Home = () => {
  const [weekScheduleData, setWeekScheduleData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [noDataMEssage, setNoDataMessage] = useState(false);
  const [diaSet, setDiaSet] = useState({});
  const [userData, setUserData] = useState(() => {
    const saved = localStorage.getItem("userData");
    const initialValue = JSON.parse(saved);
    return (
      initialValue || {
        token: "",
        email: "",
        nome: "",
        permissoes: "",
        expirationDate: "",
        entidade_ativa: "",
        entidades: [],
      }
    );
  });

  const images = [
    { url: "../assets/images/A.png" },
    { url: "../assets/images/B.png" },
    { url: "../assets/images/C.png" },
    { url: "../assets/images/D.png" },
    { url: "../assets/images/E.png" },
    { url: "../assets/images/F.png" },
    { url: "../assets/images/G.png" },
    { url: "../assets/images/H.png" },
    { url: "../assets/images/I.png" },
    { url: "../assets/images/J.png" },
    { url: "../assets/images/L.png" },
  ];

  const imagesSmaller = [
    { url: "../assets/images/A-Smaller.png" },
    { url: "../assets/images/B-Smaller.png" },
    { url: "../assets/images/C-Smaller.png" },
    { url: "../assets/images/D-Smaller.png" },
    { url: "../assets/images/E-Smaller.png" },
    { url: "../assets/images/F-Smaller.png" },
    { url: "../assets/images/G-Smaller.png" },
    { url: "../assets/images/H-Smaller.png" },
    { url: "../assets/images/I-Smaller.png" },
    { url: "../assets/images/J-Smaller.png" },
    { url: "../assets/images/L-Smaller.png" },
  ];

  const navigate = useNavigate();

  function getCurrentWeekdays() {
    const weekdays = [];
    const today = new Date();
    const currentDayOfWeek = today.getDay();

    // Calcula a data da segunda-feira da semana atual
    const monday = new Date(today);
    monday.setDate(today.getDate() - currentDayOfWeek + 0);

    // Loop para adicionar os dias da semana de segunda a sexta-feira
    for (let i = 0; i < 7; i++) {
      const weekday = {
        americanDate: new Date(monday),
        brazilianDate: new Date(monday),
      };
      weekday.americanDate.setDate(monday.getDate() + i);
      weekday.brazilianDate.setDate(monday.getDate() + i);
      const dates = {
        americanDate: weekday.americanDate.toLocaleDateString("en-US", options),
        brazilianDate: weekday.brazilianDate.toLocaleDateString(
          "pt-BR",
          options,
        ),
      };
      weekdays.push(dates);
    }

    return weekdays;
  }

  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const weekdaysThisWeek = getCurrentWeekdays();
  const primeiroDia = new Date(weekdaysThisWeek[0].americanDate)
    .toLocaleDateString("en-US", options)
    .replace(/\//g, "-");
  const ultimoDia = new Date(
    weekdaysThisWeek[weekdaysThisWeek.length - 1].americanDate,
  )
    .toLocaleDateString("en-US", options)
    .replace(/\//g, "-");

  useEffect(() => {
    tokenValidation() ? "" : navigate("/login");
    if (dateValidation() == true) {
      setTimeout(() => {
        alert(
          "Seu token expirou, por favor faça login novamente no Portal Gruppe",
        );
        localStorage.removeItem("userData");
        navigate("/login");
      }, 1000);
    }
    axios({
      method: "get",
      url: `https://portal.gruppe.com.br/cliente/agenda/${primeiroDia}/${ultimoDia}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    })
      .then((response) => {
        setWeekScheduleData(arrangeArray(response.data.agenda));
        if (response.data.agenda.length == 0) setNoDataMessage(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        console.error("Error:", error.response.data);
        if (error.response.data.message == "Token has expired") {
          alert(
            "Seu token expirou, por favor faça login novamente no Portal Gruppe",
          );
          localStorage.removeItem("userData");
          navigate("/login");
        }
      });
  }, []);

  const arrangeArray = (data) => {
    const outputArray = [];
    for (const dateKey in data) {
      if (data.hasOwnProperty(dateKey)) {
        const consultants = data[dateKey];
        for (const consultantName in consultants) {
          if (consultants.hasOwnProperty(consultantName)) {
            const events = consultants[consultantName];
            events.forEach((event) => {
              const newEvent = {
                event_id: event.evento_id,
                title: event.texto,
                start: new Date(event.data_americana).toLocaleDateString(
                  "en-US",
                  options,
                ),
                end: new Date(event.data_americana).toLocaleDateString(
                  "en-US",
                  options,
                ),
              };
              outputArray.push(newEvent);
            });
          }
        }
      }
    }
    return outputArray;
  };

  const weekDay = (param) => {
    switch (param) {
      case 0:
        return "Dom - ";
      case 1:
        return "Seg - ";
      case 2:
        return "Ter - ";
      case 3:
        return "Qua - ";
      case 4:
        return "Qui - ";
      case 5:
        return "Sex - ";
      case 6:
        return "Sab - ";
      default:
        return "";
    }
  };

  const toggleModalOpen = (dia, dia_brasil, titulo) => {
    setModalOpen(true);
    setDiaSet({ dia: dia, dia_brasil: dia_brasil });
  };

  const getBusinessName = (texto) => {
    const regexNomeConsultor = /^(.*?) - /;
    const matchNomeConsultor = texto.match(regexNomeConsultor);

    if (matchNomeConsultor) {
      const restanteDoTexto = texto.substring(matchNomeConsultor[0].length);
      const textoFinal = restanteDoTexto
        .replace(/\(Hora Início: \d{2}:\d{2}\)/, "")
        .trim();

      return textoFinal;
    } else {
      console.log("Texto no formato incorreto.");
    }
  };

  const getConsultantName = (texto) => {
    const regexNomeConsultor = /^(.*?) - /;
    const matchNomeConsultor = texto.match(regexNomeConsultor);

    if (matchNomeConsultor) {
      const nomeConsultor = matchNomeConsultor[1];
      return nomeConsultor;
    } else {
      console.log("Texto no formato incorreto.");
    }
  };

  const toggleModalClose = () => {
    setModalOpen(false);
  };

  let contagem = 0;
  let indexGERAL = 0;

  const windowWidth = useRef(window.innerWidth);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  console.log(windowSize.innerWidth);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          marginTop: "20px",
          marginLeft: "20px",
        }}
      >
        <div>
          <SimpleImageSlider
            width={windowSize.innerWidth < 1050 ? 800 : 880}
            height={248}
            images={imagesSmaller}
            showBullets={true}
            showNavs={true}
            navSize={35}
            autoPlay={true}
            autoPlayDelay={5.0}
            loop={true}
            onClick={() => window.open("https://gruppe.com.br/")}
          />
        </div>
        <LineGraphHome />
      </div>
      <h2
        style={{
          fontSize: "20px",
          marginLeft: "20px",
          marginTop: "40px",
        }}
      >
        Agenda Semanal
      </h2>

      <div className="task-boxes-container">
        {modalOpen ? (
          <div>
            <div className="custom-modal-week">
              <div className="modal-content-week">
                <div className="modal-header-content">
                  <h2 style={{ marginTop: "10px" }}>
                    Agenda {diaSet.dia_brasil}
                  </h2>
                  <button onClick={toggleModalClose}>Fechar</button>
                </div>
                <hr
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    border: "0",
                    borderTop: "2px solid #e5e5ea",
                  }}
                />
                <div className="modal-li-week">
                  {weekScheduleData.map((items, index) => {
                    if (diaSet.dia == items.start) {
                      return (
                        <li className="modal-items-content" key={items.title}>
                          <h2
                            style={{
                              marginBottom: "7px",
                              marginTop: "7px",
                              marginLeft: "7px",
                            }}
                          >
                            {getBusinessName(items.title)}
                          </h2>
                          <p
                            style={{
                              color: "#ffffff",
                              marginTop: "0px",
                              marginLeft: "7px",
                            }}
                          >
                            Consultor: {getConsultantName(items.title)}
                          </p>
                          {/* <p
                            style={{
                              color: "#ffffff",
                              marginTop: "0px",
                              marginLeft: "7px",
                            }}
                          >
                            Horas Totais:{" "}
                          </p> */}
                        </li>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {weekdaysThisWeek.map((day, index) => (
          <div className="task-box" key={day.brazilianDate}>
            <div className="day-name">
              {weekDay(index)}
              {day.brazilianDate.replace(/\/\d{4}$/g, "")}
            </div>

            <ul className="task-list">
              {weekScheduleData.length > 0 ? (
                weekScheduleData.map((items, indexx) => {
                  if (day.americanDate == items.start) {
                    if (index == indexGERAL) {
                      contagem++;
                    }
                    if (index != indexGERAL) {
                      contagem = 1;
                      indexGERAL = index;
                    }
                    const valorContatem = 3;
                    if (contagem < valorContatem) {
                      return (
                        <div className="weekly-information-content">
                          <button
                            className="asd"
                            onClick={() =>
                              toggleModalOpen(
                                items.start,
                                day.brazilianDate,
                                items.title,
                              )
                            }
                          >
                            <li key={items.title}>{items.title}</li>
                          </button>
                        </div>
                      );
                    }
                    if (contagem == valorContatem) {
                      return (
                        <div className="see-more-button">
                          <li
                            style={{
                              fontSize: "12px",
                            }}
                            key={items.title}
                          >
                            <button
                              onClick={() =>
                                toggleModalOpen(
                                  items.start,
                                  day.brazilianDate,
                                  items.title,
                                )
                              }
                              style={{ fontSize: "12px", marginTop: "12px" }}
                            >
                              <AddCircleOutlineOutlinedIcon />
                            </button>
                          </li>
                        </div>
                      );
                    }
                  }
                })
              ) : (
                <li style={{ fontSize: "12px" }} key={"nada"}>
                  {noDataMEssage ? (
                    "Nenhuma agenda nesta semana"
                  ) : (
                    <div>
                      <Loader />
                      Carregando
                    </div>
                  )}
                </li>
              )}
              {/* {weekScheduleData.length > 0 ? <div>asdasd</div> : ""} */}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
